import * as React from "react";

function SvgCEMark(props) {
  return (
    <svg
      width="38"
      height="42"
      viewBox="0 0 38 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_758_37)">
        <path
          d="M22.5193 13.0314C22.5193 20.2075 28.4616 26.0629 35.7414 26.0629C36.1142 26.0629 36.487 26.0629 36.8597 26.0189V22.0566C36.487 22.1006 36.1142 22.1226 35.7414 22.1226C31.2902 22.1226 27.5845 19.0849 26.6635 15.0126H34.2942V11.0723H26.6635C27.5845 7 31.3121 3.96226 35.7414 3.96226C36.1142 3.96226 36.487 3.98428 36.8597 4.0283V0.0440252C36.487 0.0220126 36.1142 0 35.7414 0C28.4397 0 22.5193 5.85535 22.5193 13.0314Z"
          fill="black"
        />
        <path
          d="M0 13.0314C0 20.2075 5.9423 26.0629 13.2222 26.0629C13.6826 26.0629 14.1212 26.0629 14.5817 25.9969V22.0126C14.1431 22.0786 13.6826 22.1006 13.2222 22.1006C8.09117 22.1006 3.92499 18.0283 3.92499 13.0094C3.92499 7.99057 8.09117 3.94025 13.2222 3.94025C13.6826 3.94025 14.1431 3.98428 14.5817 4.0283V0.0660377C14.1431 0.0220126 13.6826 0 13.2222 0C5.9423 0 0 5.85535 0 13.0314Z"
          fill="black"
        />
        <path
          d="M10.6567 33.0629H10.6348L8.90253 34.0095L8.6394 32.9749L10.8102 31.8082H11.9504V41.8239H10.6348V33.0629H10.6567Z"
          fill="black"
        />
        <path
          d="M21.2915 32.7547C21.0064 32.7547 20.6556 32.7547 20.2828 32.8208C18.1559 33.173 17.0376 34.7359 16.8183 36.3868H16.8622C17.3446 35.7484 18.1778 35.2201 19.2742 35.2201C21.0503 35.2201 22.2782 36.4969 22.2782 38.456C22.2782 40.283 21.0283 41.978 18.9672 41.978C16.906 41.978 15.4369 40.305 15.4369 37.7076C15.4369 35.7264 16.1386 34.1855 17.1253 33.195C17.9585 32.3805 19.0549 31.8742 20.3267 31.7201C20.7214 31.6541 21.0722 31.6321 21.3134 31.6321V32.7327L21.2915 32.7547ZM20.8968 38.544C20.8968 37.1132 20.0855 36.2547 18.8356 36.2547C18.0243 36.2547 17.2788 36.761 16.906 37.4874C16.8183 37.6415 16.7525 37.8396 16.7525 38.0818C16.7745 39.7327 17.5419 40.9434 18.9453 40.9434C20.1074 40.9434 20.8968 39.9748 20.8968 38.544Z"
          fill="black"
        />
        <path
          d="M23.8131 40.261C24.1859 40.5032 25.0849 40.8994 26.0278 40.8994C27.76 40.8994 28.3082 39.7987 28.2863 38.9623C28.2863 37.5535 27.0145 36.9591 25.6989 36.9591H24.9533V35.9465H25.6989C26.6856 35.9465 27.9354 35.4403 27.9354 34.2516C27.9354 33.4591 27.4311 32.7327 26.1813 32.7327C25.3919 32.7327 24.6244 33.0849 24.1859 33.3931L23.835 32.4025C24.3613 32.0283 25.37 31.6321 26.4444 31.6321C28.4179 31.6321 29.2949 32.7987 29.2949 34.0094C29.2949 35.044 28.681 35.9245 27.453 36.3648C28.681 36.6509 29.6896 37.5755 29.6896 38.9623C29.6896 40.5692 28.4398 41.978 26.0497 41.978C24.9314 41.978 23.9447 41.6258 23.4623 41.2956L23.835 40.239L23.8131 40.261Z"
          fill="black"
        />
        <path
          d="M32.1455 40.8553C32.4305 40.8774 32.7814 40.8554 33.2419 40.8113C34.0312 40.7013 34.7548 40.3711 35.325 39.8428C35.9828 39.2484 36.4652 38.3679 36.6406 37.1792H36.5967C36.0486 37.8616 35.2372 38.2579 34.2505 38.2579C32.4525 38.2579 31.3123 36.8931 31.3123 35.1981C31.3123 33.305 32.6717 31.6321 34.711 31.6321C36.7502 31.6321 38.022 33.305 38.022 35.8585C38.022 38.0598 37.2765 39.6006 36.3117 40.5692C35.5442 41.3176 34.4917 41.7799 33.4173 41.912C32.9349 42 32.4963 42 32.1674 42V40.8994L32.1455 40.8553ZM32.6279 35.0881C32.6279 36.3428 33.3734 37.2233 34.5575 37.2233C35.4565 37.2233 36.1801 36.783 36.531 36.1667C36.6187 36.0346 36.6625 35.8805 36.6625 35.6824C36.6625 33.9654 36.0266 32.6667 34.6233 32.6667C33.4611 32.6667 32.6498 33.6792 32.6498 35.1101L32.6279 35.0881Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_758_37">
          <rect width="38" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCEMark;
