import * as React from "react";

function SvgAngeliniPharma(props) {
  return (
    <svg
      width="72"
      height="30"
      viewBox="0 0 72 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.31123 25.3018C4.26108 24.1868 3.43771 22.8784 2.88697 21.4492C2.38815 20.119 1.98909 18.1138 2.88697 16.1468C3.72438 14.3471 5.16159 12.8946 6.95234 12.0382C9.54288 10.7379 13.289 10.1809 18.0926 10.3754V8.01602C16.7625 7.96281 15.5154 7.96281 14.3515 8.01602L10.7201 0H7.69724L0.750358 15.1558C-0.900733 18.7772 0.376246 22.791 2.60763 25.7407C3.51908 25.6667 4.42316 25.5199 5.31123 25.3018ZM9.20534 2.37438L11.8657 8.23217C9.53789 8.53146 7.56423 9.09347 5.90482 9.92484L5.70031 10.0329L9.20534 2.37438Z"
        fill="#003087"
      />
      <path
        d="M17.5788 15.159L16.1672 12.0381C15.3376 12.0604 14.511 12.1466 13.6947 12.2958L15.4322 16.1351C15.84 17.0329 16.0526 18.0071 16.0558 18.9933C16.055 19.9725 15.8516 20.9409 15.4584 21.8377C15.0653 22.7345 14.4908 23.5403 13.7712 24.2043C11.8657 25.9884 8.86114 27.0525 4.94208 27.3535C4.65942 27.3751 4.37176 27.3917 4.07912 27.4067C3.15797 27.4499 2.19137 27.4533 1.17932 27.4167V29.7728C1.66262 29.7905 2.1365 29.7994 2.60096 29.7994C8.34403 29.7994 12.6372 28.4991 15.384 25.9219C16.8355 24.5868 17.8289 22.8279 18.2229 20.8956C18.617 18.9633 18.3916 16.9559 17.5788 15.159Z"
        fill="#003087"
      />
      <path
        d="M34.4622 12.1559L34.0016 10.3751H31.4044L30.9322 12.1576H29.1581L31.4859 4.12158H34.0498L36.3777 12.1576L34.4622 12.1559ZM31.737 9.10811H33.6807L32.7379 5.40687H32.7263L31.737 9.10811Z"
        fill="#003087"
      />
      <path
        d="M37.234 12.1562V6.50291H38.802V7.15803C39.1927 6.59436 39.7098 6.32666 40.5611 6.32666C41.725 6.32666 42.4916 6.78724 42.4916 7.95946V12.1678H40.779V8.38013C40.779 7.76991 40.5146 7.57537 40.0424 7.57537C39.895 7.56989 39.7481 7.59511 39.611 7.64942C39.4739 7.70372 39.3495 7.78594 39.2459 7.89085C39.1422 7.99575 39.0615 8.12106 39.0088 8.25881C38.9562 8.39657 38.9327 8.54376 38.94 8.69106V12.1512L37.234 12.1562Z"
        fill="#003087"
      />
      <path
        d="M45.8786 6.31494C46.6268 6.31494 47.2354 6.57931 47.5114 7.24607H47.523C47.5491 6.99476 47.5913 6.74539 47.6494 6.4995H49.1325C49.0976 6.89025 49.0627 7.39572 49.0627 8.2354V12.3706C49.0627 13.7158 47.9936 14.3476 46.2926 14.3476C44.5334 14.3476 43.6721 13.8072 43.6023 12.773L45.2866 12.7031C45.4928 13.1754 45.827 13.2785 46.3209 13.2785C47.0109 13.2785 47.39 12.9908 47.39 12.1528V11.3214H47.3784C47.0907 11.8202 46.6318 12.1029 45.7156 12.1029C44.2907 12.1029 43.5325 11.2715 43.5325 9.3328C43.5341 7.35082 44.3505 6.31494 45.8786 6.31494ZM46.2577 10.949C46.9128 10.949 47.385 10.5349 47.385 9.60382V8.85559C47.385 8.03919 46.9943 7.50047 46.3275 7.50047C45.6608 7.50047 45.2118 7.9361 45.2118 9.31617C45.2118 10.5582 45.5909 10.949 46.2577 10.949Z"
        fill="#003087"
      />
      <path
        d="M55.5706 9.69527H51.7463C51.758 10.8592 52.1371 11.2582 52.9302 11.2582C53.3891 11.2582 53.7616 11.1551 53.9411 10.6613L55.4592 10.7062C55.2929 11.9017 54.3784 12.339 52.8604 12.339C50.9299 12.339 50.1135 11.2699 50.1135 9.3727C50.1135 7.44227 51.0097 6.31494 52.8487 6.31494C54.8357 6.31494 55.6621 7.33918 55.5706 9.69527ZM51.7463 8.68433H53.9328C53.9445 7.68669 53.5886 7.32754 52.8986 7.32754C52.1221 7.32754 51.7713 7.82137 51.7546 8.68433H51.7463Z"
        fill="#003087"
      />
      <path
        d="M56.6747 4.05713H58.3873V10.442C58.3873 10.9408 58.4339 11.0639 58.9975 11.0639H59.2968L59.2852 12.1563C58.923 12.205 58.5582 12.2317 58.1928 12.2361C56.9856 12.2361 56.6747 11.8337 56.6747 10.9142V4.05713Z"
        fill="#003087"
      />
      <path
        d="M60.2628 4.76356C60.2628 4.26474 60.6186 3.89062 61.2388 3.89062C61.859 3.89062 62.1816 4.25809 62.1816 4.76356C62.1816 5.26903 61.8374 5.64813 61.2388 5.64813C60.6403 5.64813 60.2628 5.28067 60.2628 4.76356ZM60.3659 6.49945H62.0802V12.1527H60.3676L60.3659 6.49945Z"
        fill="#003087"
      />
      <path
        d="M63.5383 12.1562V6.50291H65.103V7.15803H65.1129C65.5053 6.59436 66.0224 6.32666 66.8721 6.32666C68.036 6.32666 68.8042 6.78724 68.8042 7.95946V12.1678H67.0916V8.38013C67.0916 7.76991 66.8272 7.57537 66.355 7.57537C66.2076 7.56989 66.0607 7.59511 65.9236 7.64942C65.7865 7.70372 65.6622 7.78594 65.5585 7.89085C65.4548 7.99575 65.3741 8.12106 65.3215 8.25881C65.2688 8.39657 65.2454 8.54376 65.2526 8.69106V12.1512L63.5383 12.1562Z"
        fill="#003087"
      />
      <path
        d="M70.0812 4.76356C70.0812 4.26474 70.437 3.89062 71.0572 3.89062C71.6774 3.89062 72 4.25809 72 4.76356C72 5.26903 71.6558 5.64813 71.0572 5.64813C70.4586 5.64813 70.0812 5.28067 70.0812 4.76356ZM70.1843 6.49945H71.8902V12.1527H70.1776L70.1843 6.49945Z"
        fill="#003087"
      />
      <path
        d="M29.9229 25.6276V17.6465H32.4934C34.0198 17.6465 34.8013 18.29 34.8013 20.0458C34.8013 21.5955 34.0098 22.4451 32.6198 22.4451H30.8972V25.6259L29.9229 25.6276ZM30.8972 21.6819H32.4835C33.3447 21.6819 33.8136 21.2463 33.8136 20.0874C33.8136 18.6408 33.2982 18.4246 32.402 18.4246H30.8972V21.6819Z"
        fill="#003087"
      />
      <path
        d="M35.9154 25.6275V17.5898H36.8565V20.7241H36.8681C37.2007 20.1504 37.7178 19.8412 38.5558 19.8412C39.5435 19.8412 40.4497 20.2535 40.4497 21.459V25.6275H39.5086V21.6369C39.5086 20.764 39.038 20.5911 38.4295 20.5911C37.3271 20.5911 36.8565 21.3609 36.8565 22.026V25.6325L35.9154 25.6275Z"
        fill="#003087"
      />
      <path
        d="M44.3422 22.1708L45.0888 22.1259V21.3793C45.0888 20.7009 44.5899 20.518 44.0096 20.518C43.3778 20.518 42.9538 20.7009 42.736 21.253L41.8631 21.218C42.0692 20.2304 42.884 19.8413 43.998 19.8413C45.112 19.8413 45.985 20.2653 45.985 21.4026V24.5369C45.985 24.9043 46.0182 25.2701 46.0415 25.6276H45.2101C45.1636 25.3516 45.142 25.1903 45.1303 25.0989H45.1203C44.9098 25.3349 44.6487 25.5205 44.3565 25.6417C44.0643 25.7629 43.7485 25.8166 43.4327 25.7989C42.5132 25.7989 41.7101 25.2252 41.7101 24.1361C41.7101 23.0471 42.3452 22.2972 44.3422 22.1708ZM43.6655 25.1338C43.945 25.1318 44.2201 25.063 44.4676 24.9331C44.7152 24.8032 44.9282 24.6161 45.0888 24.3872V22.7794L44.5267 22.8143C42.884 22.9174 42.6213 23.4345 42.6213 24.0879C42.6262 24.6749 42.9305 25.1338 43.6655 25.1338Z"
        fill="#003087"
      />
      <path
        d="M48.6121 20.0193C48.647 20.445 48.647 20.7542 48.647 20.9272H48.6586C48.7663 20.6166 48.9686 20.3477 49.237 20.158C49.5054 19.9683 49.8265 19.8675 50.1551 19.8697C50.3335 19.8722 50.5109 19.8956 50.6839 19.9395L50.6606 20.7193C50.4428 20.7077 50.3846 20.7077 50.2349 20.7077C48.9729 20.7077 48.662 21.4542 48.662 22.7528V25.6343H47.7209V21.0169C47.7209 20.6844 47.7209 20.3519 47.7092 20.0193H48.6121Z"
        fill="#003087"
      />
      <path
        d="M59.0041 21.4595V25.628H58.063V21.6374C58.063 20.7645 57.7072 20.5916 57.1901 20.5916C56.3404 20.5916 55.8233 21.3265 55.8233 22.0382V25.633H54.8822V21.6424C54.8822 20.7695 54.5264 20.5966 54.0093 20.5966C53.1596 20.5966 52.6442 21.3664 52.6442 22.0781V25.638H51.7014V20.0196H52.6209V20.7313H52.6325C52.7804 20.4547 53.003 20.2253 53.2749 20.0692C53.5469 19.9131 53.8572 19.8366 54.1706 19.8484C54.8024 19.8484 55.411 20.0778 55.6853 20.698H55.7319C56.1226 20.1809 56.5815 19.8484 57.3497 19.8484C58.1994 19.8417 59.0041 20.2541 59.0041 21.4595Z"
        fill="#003087"
      />
      <path
        d="M62.8966 22.1708L63.6432 22.1259V21.3793C63.6432 20.7009 63.1443 20.518 62.564 20.518C61.9322 20.518 61.5065 20.7009 61.2887 21.253L60.4158 21.218C60.6236 20.2304 61.4384 19.8413 62.5524 19.8413C63.6664 19.8413 64.5377 20.2653 64.5377 21.4026V24.5369C64.5377 24.9043 64.5726 25.2701 64.5959 25.6276H63.7645C63.718 25.3516 63.6947 25.1903 63.6831 25.0989H63.6714C63.1892 25.616 62.6738 25.7989 61.9837 25.7989C61.0659 25.7989 60.2628 25.2252 60.2628 24.1361C60.2628 23.0471 60.898 22.2972 62.8966 22.1708ZM62.2182 25.1338C62.498 25.1316 62.7732 25.0628 63.021 24.9329C63.2688 24.8031 63.4821 24.616 63.6432 24.3872V22.7794L63.0795 22.8143C61.4384 22.9174 61.174 23.4345 61.174 24.0879C61.174 24.6749 61.4849 25.1338 62.2182 25.1338Z"
        fill="#003087"
      />
    </svg>
  );
}

export default SvgAngeliniPharma;
