import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  breakpoints,
  colors,
  Container,
  H2,
  LinkButton,
  InteractiveText,
} from "../../styles";
import { Arrow } from "assets/svgs";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "hooks";

const recommendedSlides = [
  {
    id: 1,
    text: "People with poor sitting postures in work or in the home.",
    image: (
      <StaticImage
        src="../../assets/images/recommendations/slide-1.jpg"
        alt="Poor Posture"
        placeholder="blurred"
        layout="fullWidth"
        loading="lazy"
        objectFit="cover"
        objectPosition="center"
      />
    ),
  },
  {
    id: 2,
    text: "People working in a standing position for a long time.",
    image: (
      <StaticImage
        src="../../assets/images/recommendations/slide-2.jpg"
        alt="Standing for long periods"
        placeholder="blurred"
        layout="fullWidth"
        loading="lazy"
        objectFit="cover"
        objectPosition="center"
      />
    ),
  },
  {
    id: 3,
    text: "People who play sports for pleasure and/or for competition",
    image: (
      <StaticImage
        src="../../assets/images/recommendations/slide-3.jpg"
        alt="Sports players"
        placeholder="blurred"
        layout="fullWidth"
        loading="lazy"
        objectFit="cover"
        objectPosition="center"
      />
    ),
  },
  {
    id: 4,
    text: "People doing housework or looking after children and / or the elderly.",
    image: (
      <StaticImage
        src="../../assets/images/recommendations/slide-4.jpg"
        alt="Houseworkers and carers"
        placeholder="blurred"
        layout="fullWidth"
        loading="lazy"
        objectFit="cover"
        objectPosition="center"
      />
    ),
  },
  {
    id: 5,
    text: "Jobs and hobbies involving physical exertion",
    image: (
      <StaticImage
        src="../../assets/images/recommendations/slide-5.jpg"
        alt="Physical Exertion"
        placeholder="blurred"
        layout="fullWidth"
        loading="lazy"
        objectFit="cover"
        objectPosition="center"
      />
    ),
  },
];

const Recommendations = () => {
  const [index, setIndex] = useState(0);
  const paginate = useCallback(() => {
    index !== recommendedSlides.length - 1 ? setIndex(index + 1) : setIndex(0);
  }, [index]);

  useEffect(() => {
    const timer = setTimeout(() => paginate(), 5000);
    return () => clearTimeout(timer);
  }, [paginate]);

  const { windowWidth } = useWindowSize();
  const mobile = windowWidth < 768;
  return (
    <StyledContainer>
      <SlideImage>{recommendedSlides[index].image}</SlideImage>
      <TextBox>
        <H2>ThermaCare® is recommended for:</H2>
        {recommendedSlides.map((slide) => (
          <InteractiveText
            key={slide.id}
            $selected={recommendedSlides[index].id === slide.id}
            $mobile={mobile}
            onClick={() => setIndex(slide.id - 1)}
          >
            <Arrow stroke="2px" />
            <p>{slide.text}</p>
          </InteractiveText>
        ))}
        <LinkButton to="/products">View Products</LinkButton>
      </TextBox>
    </StyledContainer>
  );
};

const SlideImage = styled.div`
  grid-column: 1/7;
  overflow: hidden;
  position: relative;
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  &:after {
    background-image: linear-gradient(
      rgba(242, 242, 242, 0),
      rgba(242, 242, 242, 1) 75%
    );
    bottom: -0.25rem;
    content: "";
    height: 5rem;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;
  }
  ${breakpoints.md} {
    grid-column: 1/4;
    &:after {
      background-image: linear-gradient(
        to right,
        rgba(242, 242, 242, 0),
        rgba(242, 242, 242, 1) 75%
      );
      content: "";
      height: 100%;
      position: absolute;
      right: -0.25rem;
      top: 0;
      width: 7.5rem;
      z-index: 2;
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/6;
  }
`;

const TextBox = styled.div`
  align-items: flex-start;
  background: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  padding: 1.5rem;
  ${breakpoints.sm} {
    padding: 4rem;
  }
  ${breakpoints.md} {
    grid-column: 4/7;
  }
  ${breakpoints.lg} {
    grid-column: 6/12;
  }
`;
const StyledContainer = styled(Container)`
  grid-gap: 0;
`;

export default Recommendations;
