import * as React from "react";

function SvgRest(props) {
  return (
    <svg
      viewBox="0 0 104 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.937 70.775c-5.4-.896-9.394-2.09-11.169-3.509-7.322-5.972-7.026-50.615 0-57.707C19.753-.52 84.324-.52 94.31 9.559c7.026 7.092 7.322 51.735 0 57.707-1.776 1.493-5.77 2.613-11.169 3.51"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M52 58.084c10.702 0 19.379-8.757 19.379-19.56 0-10.802-8.677-19.559-19.38-19.559-10.702 0-19.378 8.757-19.378 19.56 0 10.802 8.676 19.559 19.379 19.559zM25.596 110.118V88.916L17.09 114"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M102 112.134S89.204 80.928 83.73 71.148c-2.81-5.001-8.653-7.54-14.422-7.54H34.692c-5.695 0-11.538 2.464-14.423 7.54C14.722 80.928 2 112.135 2 112.135"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M78.48 110.118V88.916L86.987 114"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRest;
