import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { H1, H5, colors, breakpoints } from "styles";
import { Markup } from "interweave";

const ProductHowTo = ({ instructions, closeModal }) => {
  const data = useStaticQuery(graphql`
    query {
      topFlame: file(relativePath: { eq: "instructions-top-flame.png" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
        }
      }
      bottomFlame: file(relativePath: { eq: "instructions-bottom-flame.png" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  `);
  return (
    <StyledModal
      key="modal"
      role="dialog"
      aria-label="Product How To Use"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.25 }}
    >
      <ModalBody>
        {data && (
          <>
            <GatsbyImage image={getImage(data.topFlame)} alt="Background" />
            <GatsbyImage image={getImage(data.bottomFlame)} alt="Background" />
          </>
        )}
        <CloseButton
          aria-label="Close Product How To Use"
          onClick={() => closeModal()}
        />
        <H5
          $color="white"
          dangerouslySetInnerHTML={{ __html: instructions.supTitle }}
        />
        <H1
          $color="white"
          dangerouslySetInnerHTML={{ __html: instructions.title }}
        />
        <Markup content={instructions.content} tagName="div" />
      </ModalBody>
    </StyledModal>
  );
};

const CloseButton = styled.button`
  background: ${colors.orange};
  border-radius: 50%;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.125);
  cursor: pointer;
  height: 3rem;
  margin-bottom: 1.5rem;
  outline: none;
  position: absolute;
  z-index: 3;
  right: 1.25rem;
  top: 1.25rem;
  width: 3rem;
  &:before {
    background: white;
    content: "";
    height: 1.25rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 2px;
  }
  &:after {
    background: white;
    content: "";
    height: 1.25rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 2px;
  }
  ${breakpoints.sm} {
    top: 1.5rem;
    right: 1.5rem;
  }
`;

const StyledModal = styled(motion.div)`
  background: ${colors.darkRed};
  border-radius: 1rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: white;
  height: auto;
  max-width: 1440px;
  max-height: 100%;
  position: relative;
  width: 100%;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: overlay;
  overscroll-behavior: none;
  &::-webkit-scrollbar {
    width: 20px;
    left: -20px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb:focus {
    background: white;
    border-radius: 10px;
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
`;

const ModalBody = styled.div`
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 1.25rem;
  padding-top: 5rem;
  padding-right: 1.5rem;
  position: relative;
  overflow: visible;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1;
    &:first-of-type {
      top: 0;
      left: 0;
    }
    &:nth-of-type(2) {
      bottom: 0;
      left: 0;
    }
  }
  h5 {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 0;
    position: relative;
    z-index: 3;
  }
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    position: relative;
    z-index: 3;
  }
  ul {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    list-style: none;
    position: relative;
    z-index: 3;
    li {
      list-style-type: none;
      position: relative;
      margin-bottom: 1rem;
      padding-left: 0.25rem;
      font-size: 0.875rem;
      ul {
        li {
          margin-bottom: 0.25rem;
          padding-left: 0;
          &:before {
            content: "";
            position: absolute;
            left: -1.5rem;
            top: 0.5rem;
            height: 0.3rem;
            width: 0.3rem;
            background: white;
            border-radius: 50%;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: -1.5rem;
        top: 0.25rem;
        height: 0.75rem;
        width: 0.75rem;
        background: ${colors.orange};
        border-radius: 50%;
      }
    }
  }
  ${breakpoints.sm} {
    padding: 3rem;
    ul {
      li {
        margin-bottom: 1em;
      }
    }
  }
  ${breakpoints.md} {
    h5 {
      font-size: 2rem;
      line-height: 5rem;
    }
    h1 {
      font-size: 4rem;
      line-height: 5rem;
    }
    ul {
      li {
        margin-bottom: 2.5rem;
        padding-left: 1rem;
        font-size: 1.25rem;
      }
    }
  }
`;

export default ProductHowTo;
