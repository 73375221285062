import * as React from "react";

function SvgBloodCirculation(props) {
  return (
    <svg
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.03 33.92c-2.762-8.1-13.57-9.42-18.433-3.96-14.95 16.8 18.793 35.28 18.793 35.28S76.333 46.7 63.184 29.96c-4.624-5.76-15.912-4.32-19.154 3.96z"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M17.5 76.5c7.145 5.64 16.683 9.5 26.47 9.5C67.147 86 86 67.22 86 44c0-9.84-3.362-18.84-9.067-25.98M69.248 10.46C62.223 5.12 53.517 2 44.03 2 20.793 2 2 20.78 2 44c0 9.48 3.122 18.24 8.466 25.26"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M22.615 75.16h-6.304v6.3M64.124 12.18h6.304v-6.3"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgBloodCirculation;
