import * as React from "react";
import { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import styled from "styled-components";
import { Arrow } from "assets/svgs";
import { colors, breakpoints, FullWidthContent } from "styles";
import { Markup } from "interweave";

const Accordion = ({ i, expanded, setExpanded, question, answer }) => {
  const isOpen = i === expanded;

  return (
    <FullWidthContent>
      <AnimateSharedLayout initial={false}>
        <Question
          layout
          $colors={colors}
          onClick={() => setExpanded(isOpen ? false : i)}
          $open={isOpen}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {question}
            <Arrow />
          </div>
        </Question>
        {isOpen && (
          <AnswerText
            layout
            $colors={colors}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { transform: "scaleY(1)" },
              collapsed: { transform: "scaleY(0)" },
            }}
            transition={{ duration: 0.375 }}
          >
            <Markup content={answer} tagName="p" />
          </AnswerText>
        )}
      </AnimateSharedLayout>
    </FullWidthContent>
  );
};

const Faq = ({ faqs }) => {
  const [expanded, setExpanded] = useState(false);

  return faqs.map((faq, i) => (
    <Accordion
      i={i}
      key={i}
      expanded={expanded}
      setExpanded={setExpanded}
      question={faq.question}
      answer={faq.answer}
    />
  ));
};

const Question = styled(motion.div)`
  width: 100%;
  padding: 1.5rem;
  background: ${colors.lightGrey};
  color: ${colors.red};
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  div {
    svg {
      border: 1px solid ${colors.red};
      border-radius: 50%;
      padding: 0.375rem 0.125rem 0.125rem 0.125rem;
      margin-left: 0.5rem;
      min-width: 24px;
      height: 24px;
      opacity: ${({ $open }) => ($open ? ".5" : "1")};
      transition-duration: 0.375s;
      transform: ${({ $open }) => ($open ? "rotate(0deg)" : "rotate(180deg)")};
      path {
        stroke: ${colors.red};
      }
    }
  }

  ${breakpoints.lg} {
    grid-column: 2/10;
  }
`;

const AnswerText = styled(motion.section)`
  width: 100%;
  background: ${colors.lightGrey};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 1.5rem;
  padding-top: 0;
  color: ${colors.brown};
  text-align: left;
  transform-origin: top center;
  overflow: hidden;
  opacity: 1;
`;

export default Faq;
