import * as React from "react";

function SvgBackMuscles(props) {
  return (
    <svg
      viewBox="0 0 80 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.135 1c-.335 9.248-5.017 12.88-12.933 15.083-7.916 2.201-17.839 7.155-17.17 42.715"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M13.516 58.798s1.114-13.981.78-21.358C13.85 28.963 26.448 66.064 15.968 85M40.05 58.798v24.88M47.855 1c.334 9.248 5.017 12.88 12.933 15.083 7.916 2.201 17.95 7.045 17.17 42.605"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M66.585 58.798s-1.115-13.981-.78-21.358c.446-8.477-12.153 28.624-1.673 47.56M45.513 22.688c.78 7.596 5.464 13.1 8.92 15.083M34.476 22.688c-.78 7.596-5.463 13.1-8.92 15.083"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgBackMuscles;
