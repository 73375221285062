import * as React from "react";

function SvgTimer(props) {
  return (
    <svg
      viewBox="0 0 106 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49.725 2C79.67 2 104 26.207 104 56s-24.33 54-54.275 54C29.699 110 12.2 99.2 2.749 83.186M49.725 93.242v7.728M49.725 11.124v7.635M12.293 56H4.62M94.83 56h-7.674M68.44 88.307l3.837 6.61M27.172 17.176l3.837 6.61M17.347 74.621l-6.644 3.817M88.84 33.562l-6.644 3.817M82.196 74.621l6.644 3.817M10.703 33.562l6.644 3.817M31.01 88.307l-3.838 6.61M72.277 17.176l-3.837 6.61"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M11.826 82.908H2v9.776M44.672 50.88c4.29 0 7.767-3.46 7.767-7.728s-3.478-7.728-7.767-7.728c-4.29 0-7.767 3.46-7.767 7.728s3.477 7.727 7.767 7.727zM44.672 70.431c5.375 0 9.732-4.335 9.732-9.683 0-5.347-4.358-9.683-9.732-9.683-5.375 0-9.732 4.336-9.732 9.683 0 5.348 4.357 9.683 9.732 9.683zM60.393 53.114v17.41M60.393 60.19h1.684c2.995 0 5.521 2.42 5.521 5.493v4.84"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgTimer;
