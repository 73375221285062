import React, { useState } from "react";
import "../../fonts/fonts.css";
import { Navbar, Footer, Modal } from "components";
import { OfficialSite } from "../Modals";
import { GlobalStyles } from "styles";
import styled from "styled-components";
import { motion } from "framer-motion";

const Layout = ({ children }) => {
  const [officialSiteModalOpen, setOfficialSiteModalOpen] = useState(true);
  return (
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <GlobalStyles />
        <Navbar />
        {children}
        <Footer />
        {officialSiteModalOpen && (
          <Modal
            darkBackground
            isModalOpen={officialSiteModalOpen}
            closeModal={() => setOfficialSiteModalOpen(false)}
          >
            <OfficialSite closeModal={() => setOfficialSiteModalOpen(false)} />
          </Modal>
        )}
      </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  width: 100%;
  height: auto;
`;

export default Layout;
