import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, breakpoints, H1 } from "styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Markup } from "interweave";

const Header = ({ title, content, image, data, alignBottom }) => {
  return (
    <StyledHeader
      $bg={colors.red}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {data && (
        <>
          <GatsbyImage image={getImage(data.topFlame)} alt="Background" />
          <GatsbyImage image={getImage(data.bottomFlame)} alt="Background" />
        </>
      )}
      <Container $imagePresent={image || data?.headerImage} $alignBottom={alignBottom}>
        <TextContent $imagePresent={image || data?.headerImage}>
          <H1 $color="white" dangerouslySetInnerHTML={{ __html: title }} />
          <Markup content={content} tagName="div" />
        </TextContent>
        {data && data.headerImage && (
          <HeaderImage
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
          >
            <GatsbyImage
              image={getImage(data.headerImage)}
              alt="Lower back pain"
            />
          </HeaderImage>
        )}
      </Container>
    </StyledHeader>
  );
};

const HeaderImage = styled(motion.div)`
  position: relative;
  grid-column: 3/7;
  .gatbsy-image-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  ${breakpoints.sm} {
    grid-column: 4/7;
  }
  ${breakpoints.lg} {
    grid-column: 8/12;
  }
`;

const TextContent = styled.div`
  position: relative;
  z-index: 2;
  grid-column: 1/7;
  width: 100%;
  display: flex;
  flex-direction: column;
  svg {
    width: 280px;
    height: auto;
    margin: 2rem 0;
    path {
      fill: white;
    }
  }
  ul,
  ol,
  p {
    font-family: "Gotham";
    font-weight: 400;
    font-size: 0.925rem;
    line-height: 1.4em;
    margin-bottom: 0.4em;
    color: white;
  }
  ul,
  ol {
    margin: 1.5rem;
    li {
      margin-bottom: 0.5em;
    }
  }
  ${breakpoints.sm} {
    grid-column: 1/7;
    svg {
      width: 400px;
    }
    p,
    ul,
    ol {
      font-size: 1.25rem;
    }
  }
  ${breakpoints.md} {
    grid-column: ${({ $imagePresent }) => ($imagePresent ? "1/4" : "1/7")};
    padding-right: 4rem;
    svg {
      width: 400px;
    }
    p,
    ul {
      font-size: 1.25rem;
    }
  }
  ${breakpoints.lg} {
    grid-column: ${({ $imagePresent }) => ($imagePresent ? "2/8" : "2/12")};
    margin-bottom: 0;
    padding-right: 0;
    ${H1} {
      margin-bottom: 2rem;
    }
    svg {
      width: 400px;
    }
    p,
    ul {
      font-size: 1.25rem;
    }
    ul,
    ol {
      li {
        margin-bottom: 1em;
      }
    }
  }
`;

const StyledHeader = styled(motion.header)`
  position: relative;
  width: 100%;
  height: auto;
  background: ${({ $bg }) => $bg};
  overflow: hidden;
  padding-top: 4rem;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    &:first-of-type {
      top: 0;
      right: 0;
    }
    &:nth-of-type(2) {
      bottom: 0%;
      right: 0;
    }
  }
  ${breakpoints.lg} {
    .gatsby-image-wrapper {
      &:first-of-type {
        top: -25%;
      }
      &:nth-of-type(2) {
        bottom: -10%;
      }
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: ${({ $imagePresent }) => ($imagePresent ? "1fr 1fr" : "1fr")};
  margin: 0 auto;
  max-width: 1440px;
  padding: ${({ $smallPadding }) =>
    $smallPadding ? "2rem 1rem" : "2.5rem 1.25rem"};
  position: relative;
  width: 100%;
  z-index: 1;
  ${breakpoints.sm} {
    padding: ${({ $smallPadding }) =>
      $smallPadding ? "2rem 1rem" : "5rem 2.5rem"};
    grid-gap: 1.25rem;
  }
  ${breakpoints.md} {
    grid-template-rows: 1fr;
  }
  ${breakpoints.lg} {
    grid-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    padding: ${({ $smallPadding, $alignBottom }) =>
      $smallPadding ? "2rem 1rem" : $alignBottom ? "12rem 1.25rem 4rem 1.25rem" : "8rem 1.25rem"};
  }
`;

export default Header;
