import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { breakpoints, colors, H4, P } from "styles";
import { Arrow } from "assets/svgs";

const Testimonials = () => {
  const testimonials = useMemo(
    () => [
      {
        id: 1,
        author: `Mark, 56, Senior Golfer`,
        quote: `
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            `,
      },
      {
        id: 2,
        author: `Jane, 32, Social Worker`,
        quote: `
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            `,
      },
    ],
    []
  );

  const [index, setIndex] = useState(0);

  const paginate = useCallback((dir) => {
    dir === "next" && index !== testimonials.length - 1
      ? setIndex(index + 1)
      : dir === "next" && index === testimonials.length - 1
      ? setIndex(0)
      : dir === "prev" && index === 0
      ? setIndex(testimonials.length - 1)
      : dir === "prev" && index !== 0
      ? setIndex(index - 1)
      : setIndex(1);
  },[index, testimonials]);

  useEffect(() => {
    const timer = setTimeout(() => paginate("next"), 5000);
    return () => clearTimeout(timer);
  }, [paginate]);

  return (
    <StyledTestimonials $colors={colors}>
      <H4 $center>Testimonials</H4>
      <AnimatePresence exitBeforeEnter>
        <Animated
          key={testimonials[index].id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <P $center>{testimonials[index].quote}</P>
          <Author $center>{testimonials[index].author}</Author>
        </Animated>
      </AnimatePresence>
      <Indicators $size={testimonials.length}>
        {testimonials.map((i) => (
          <Indicator key={i.id} $active={testimonials[index].id === i.id} />
        ))}
      </Indicators>
      <Prev onClick={() => paginate("prev")}>
        <Arrow />
      </Prev>
      <Next onClick={() => paginate("next")}>
        <Arrow />
      </Next>
    </StyledTestimonials>
  );
};

const Paginate = styled.button`
  align-items: center;
  background: none;
  border-radius: 50%;
  border: 2px solid ${colors.red};
  bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  outline: none;
  position: absolute;
  width: 2rem;
  svg {
    height: 0.75rem;
    width: 0.75rem;
  }
  ${breakpoints.sm} {
    bottom: initial;
    top: 50%;
  }
`;

const Prev = styled(Paginate)`
  left: 1.5rem;
  transform: rotate(-90deg);
  svg {
    margin-top: 0.125rem;
  }
  ${breakpoints.sm} {
    left: 2.5rem;
    transform: translateY(-50%) rotate(-90deg);
  }
`;
const Next = styled(Paginate)`
  right: 1.5rem;
  transform: rotate(90deg);
  svg {
    margin-top: 0.125rem;
  }
  ${breakpoints.sm} {
    right: 2.5rem;
    transform: translateY(-50%) rotate(90deg);
  }
`;

const Indicator = styled.span`
  background: ${({ $active }) => ($active ? colors.brown : "transparent")};
  border-radius: 50%;
  border: 1px solid ${colors.brown};
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
`;

const Indicators = styled.div`
  align-items: center;
  display: flex;
  height: 1rem;
  justify-content: space-between;
  width: ${({ $size }) => `calc(${$size} * .75rem)`};
`;

const Animated = styled(motion.blockquote)`
  font-style: normal;
`;

const Author = styled(P)`
  color: ${colors.red};
  font-weight: 500;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  ${breakpoints.sm} {
    margin: 2rem auto;
  }
`;

const StyledTestimonials = styled.div`
  align-items: center;
  background: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: center;
  padding: 2rem;
  position: relative;
  ${breakpoints.sm} {
    padding: 4rem 8rem;
  }
  ${breakpoints.md} {
    padding: 4rem 8rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
  }
`;

export default Testimonials;
