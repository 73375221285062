import styled, { createGlobalStyle, keyframes } from "styled-components";
import { Link } from "gatsby";

// Breakpoints
export const breakpoints = {
  xs: "@media (max-width:360px)",
  sm: "@media (min-width: 768px)",
  md: "@media (min-width: 1024px)",
  lg: "@media (min-width: 1200px)",
  xl: "@media (min-width: 1440px)",
};

// Transition ( for consistency )
export const easeTransition = "ease-in-out .375s";

// Theme Colors
export const colors = {
  brown: "#540F16",
  darkRed: "#9D2C29",
  lightGrey: "#F2F2F2",
  orange: "#F6921E",
  orangeHighlight: "#F8941f",
  red: "#CC112C",
  redHighlight: "#E93751",
};

// Browser CSS Overrides
export const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body{
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Gotham';
  font-size:16px;
  font-weight: 400;
}
`;

// Full Width Section with Optional Background Color
export const Section = styled.section`
  background: ${({ $bg }) => ($bg ? $bg : "white")};
  height: auto;
  position: relative;
  width: 100%;
`;

// 1440px Centered Container
export const Container = styled.div`
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  margin: 0 auto;
  max-width: 1440px;
  padding: ${({ $smallPadding }) =>
    $smallPadding ? "2rem 1rem" : "2.5rem 1.25rem"};
  position: relative;
  width: 100%;
  z-index: 1;
  ${breakpoints.sm} {
    padding: ${({ $smallPadding }) =>
      $smallPadding ? "2rem 1rem" : "5rem 2.5rem"};
    grid-gap: 1.25rem;
  }
  ${breakpoints.lg} {
    grid-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    padding: ${({ $smallPadding }) =>
      $smallPadding ? "2rem 1rem" : "5rem 1.25rem"};
  }
`;

export const Article = styled.article`
  margin: 3rem auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  ${breakpoints.md} {
    grid-gap: ${({ gap }) => gap || "2rem"};
    grid-template-columns: 1fr 1fr;
    > div {
      grid-column: ${({ $flipped }) => ($flipped ? 1 : 2)};
      grid-row: 1;
    }
    .gatsby-image-wrapper {
      grid-column: ${({ $flipped }) => ($flipped ? 2 : 1)};
    }
  }
  p {
    &:last-of-type {
      margin-bottom: 3rem;
    }
  }
`;

// Flex Content that fills Container
export const FullWidthContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: center;
  ${breakpoints.sm} {
    grid-column: 1/7;
    padding: 0 2rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
    padding: 0 2rem;
  }
`;

// Default Heading and Paragraph Styles
export const H1 = styled.h1`
  width: 100%;

  color: ${({ $color }) => ($color ? $color : colors.red)};
  font-family: "Gotham";
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "0.5em")};
  margin: 0;
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  ${breakpoints.sm} {
    font-size: 4rem;
    line-height: 1.25em;
  }
  ${breakpoints.lg} {
    font-size: 4rem;
    line-height: 1.25em;
  }
`;

export const H2 = styled.h2`
  width: 100%;

  color: ${({ $color }) => ($color ? $color : colors.red)};
  font-family: "Gotham";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "0.5em")};
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  ${breakpoints.sm} {
    font-size: 1.75rem;
  }
  ${breakpoints.lg} {
    font-size: 2rem;
  }
`;

export const H3 = styled.h3`
  width: 100%;
  color: ${({ $color }) => ($color ? $color : colors.red)};
  font-family: "Gotham";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "0.5em")};
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  ${breakpoints.lg} {
    font-size: 1.5rem;
  }
`;

export const H4 = styled.h4`
  width: 100%;
  color: ${({ $color }) => ($color ? $color : colors.red)};
  font-family: "Gotham";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "0.5em")};
  text-align: ${({ $center }) => ($center ? "center" : "left")};
`;

export const H5 = styled.h5`
  width: 100%;
  color: ${({ $color }) => ($color ? $color : colors.red)};
  font-family: "Gotham";
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "0.5em")};
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  ${breakpoints.sm} {
    font-weight: 500;
    font-size: 1rem;
  }
  ${breakpoints.lg} {
    font-size: 1.5rem;
    font-weight: 500;
  }
  ${breakpoints.xl} {
    font-size: 1.875rem;
  }
`;

export const H6 = styled.h6`
  width: 100%;
  color: ${({ $color }) => ($color ? $color : colors.red)};
  font-family: "Gotham";
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "0.5em")};
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  ${breakpoints.sm} {
    font-size: 0.925rem;
  }
  ${breakpoints.lg} {
    font-size: 1rem;
  }
`;

export const P = styled.p`
  width: 100%;
  color: ${({ $color }) => ($color ? $color : colors.brown)};
  font-family: "Gotham";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "1em")};
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  ${breakpoints.sm} {
    font-size: 1rem;
  }
  ${breakpoints.lg} {
    font-size: 1rem;
  }
`;

export const Small = styled.small`
  font-family: "Gotham";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4em;
  margin: 1.5rem auto;
  max-width: 500px;
  text-align: center;
  width: 100%;
  ${breakpoints.sm} {
    font-size: 1rem;
  }
  ${breakpoints.lg} {
    font-size: 1rem;
  }
`;

// Link & Button Styles
export const LinkButton = styled(Link)`
  align-items: center;
  align-self: flex-start;
  background: ${({ $bg }) => ($bg ? $bg : colors.red)};
  border-radius: 4rem;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.125);
  color: white;
  display: flex;
  font-size: 0.825rem;
  font-weight: 600;
  justify-content: center;
  line-height: 0.8em;
  margin: 1.5em 0;
  padding: 1.25em 2em;
  text-decoration: none;
  text-transform: uppercase;
  transition: ${easeTransition};
  &:hover {
    background: ${({ $bg }) => ($bg ? $bg : colors.redHighlight)};
  }
  ${breakpoints.sm} {
    font-size: 0.925rem;
    padding: 1.25rem 2.5rem;
  }
  ${breakpoints.lg} {
    font-size: 0.925rem;
    padding: 1.25rem 2.5rem;
  }
`;

export const Button = styled.button`
  align-items: center;
  align-self: flex-start;
  background: ${({ $bg }) => ($bg ? $bg : colors.red)};
  border-radius: 4rem;
  border: none;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.125);
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.825rem;
  font-weight: 600;
  justify-content: center;
  line-height: 0.8em;
  margin: 1.5em 0;
  max-width: 20rem;
  outline: none;
  padding: 1.25em 2em;
  text-decoration: none;
  text-transform: uppercase;
  transition: ${easeTransition};
  width: 100%;
  &:hover {
    background: ${({ $bg }) => ($bg ? $bg : colors.redHighlight)};
  }
  ${breakpoints.sm} {
    font-size: 0.925rem;
    padding: 1.25rem 2.5rem;
  }
  ${breakpoints.lg} {
    font-size: 0.925rem;
    padding: 1.25rem 2.5rem;
  }
`;

export const InteractiveText = styled.button`
  align-items: center;
  align-self: flex-start;
  background: none;
  border: none;
  color: ${({ $selected }) => ($selected ? colors.red : colors.brown)};
  cursor: pointer;
  display: flex;
  font-family: "Gotham";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 1em;
  outline: none;
  position: relative;
  text-align: left;
  text-decoration: underline;
  transition: ${easeTransition};
  margin-left: -1.125rem;
  svg {
    opacity: ${({ $mobile, $selected }) =>
      $mobile ? 0 : (!$mobile && $selected) ? 1 : 0};
    position: relative;
    margin-right: 0.5rem;
    transform: rotate(90deg);
    height: 0.625rem !important;
    width: 0.625rem !important;
    transition: opacity 0.375s ease-in-out;
  }
  &:hover {
    color: ${colors.red};
    svg {
      opacity: 1;
    }
  }
  ${breakpoints.sm} {
    &:before {
      margin-right: 0.75rem;
    }
  }
  ${breakpoints.lg} {
    &:before {
      margin-right: 0.75rem;
    }
  }
`;

// Card Layout
export const Cards = styled.div`
  align-items: flex-start;
  display: grid;
  grid-column-gap: 0;
  grid-column: 1/7;
  grid-row-gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-top: 3rem;
  ${breakpoints.sm} {
    grid-column: 2/6;
    grid-template-columns: 1fr;
  }
  ${breakpoints.md} {
    grid-column: 2/6;
    grid-template-columns: repeat(2, 1fr);
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
    grid-template-columns: repeat(4, 1fr);
  }
  ${breakpoints.xl} {
    grid-column: 1/13;
  }
`;

// Animations
export const Pulse = keyframes`
    0% {
      opacity:.9;
      box-shadow: 0 0 10px 10px rgba(251, 213, 184, .9), 0 0 0 16px rgba(254, 244, 215, .75), 0 0 20px 20px rgba(251, 213, 184, .9), 0 0 0 30px rgba(254, 244, 215, .75);
    }
    50% {
      opacity:.9;
      box-shadow: 0 0 10px 20px rgba(251, 213, 184, .75), 0 0 0 32px rgba(254, 244, 215, .75), 0 0 30px 50px rgba(251, 213, 184, .75), 0 0 0 64px rgba(254, 244, 215, .75);
    }
    100% {
      opacity:.9;
      box-shadow: 0 0 10px 10px rgba(251, 213, 184, .9), 0 0 0 16px rgba(254, 244, 215, .75), 0 0 20px 20px rgba(251, 213, 184, .9), 0 0 0 32px rgba(254, 244, 215, .75);
    }
`;

export const PulseMobile = keyframes`
    0% {
      opacity: .9;
      box-shadow: 0 0 0 8px rgba(251, 213, 184, .75),  0 0 0 16px rgba(251, 213, 184, .75);
    }
    50% {
      opacity: .75;
      box-shadow: 0 0 0 15px rgba(251, 213, 184, .5),  0 0 0 30px rgba(251, 213, 184, .5);
    }
    100% {
      opacity: .9;
      box-shadow: 0 0 0 8px rgba(251, 213, 184, .75),  0 0 0 16px rgba(251, 213, 184, .75);
    }
`;
