import * as React from "react";

function SvgTwitter(props) {
  return (
    <svg
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.576 2.377c-.494.21-1.018.33-1.543.42.247-.03.617-.48.772-.66.216-.269.4-.568.524-.898 0-.03.031-.06 0-.09h-.092a9.46 9.46 0 01-1.821.69c-.062 0-.093 0-.123-.03-.062-.06-.093-.12-.155-.15a4.393 4.393 0 00-.895-.54A3.641 3.641 0 0010.855.91c-.463.03-.895.15-1.296.36-.401.209-.772.479-1.05.838-.308.36-.524.778-.617 1.198-.092.419-.092.838-.03 1.287 0 .06 0 .09-.062.06-2.5-.359-4.567-1.227-6.264-3.114-.062-.09-.124-.09-.186 0-.74 1.078-.37 2.815.556 3.653.123.12.247.24.401.33-.062 0-.679-.06-1.203-.33-.062-.06-.124-.03-.124.06 0 .12 0 .21.03.36.155 1.078.927 2.096 1.976 2.485.123.06.278.09.401.12-.247.06-.494.09-1.203.03-.093-.03-.124.03-.093.12.525 1.377 1.666 1.796 2.5 2.036.123.03.216.03.339.06l-.03.03c-.279.42-1.266.719-1.698.868-.833.27-1.728.42-2.592.33-.155-.03-.155-.03-.216 0-.031.03 0 .06.03.09.186.12.37.21.525.299.556.27 1.142.51 1.728.659 3.117.838 6.635.21 8.95-2.037 1.85-1.766 2.468-4.222 2.468-6.648 0-.09.123-.15.185-.21.463-.359.864-.778 1.204-1.227.092-.09.061-.21.061-.24.093-.06.093-.03.031 0z"
        fill="#540F16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.424 13.068c0-.03.277 0 0 0 0-.03.03 0 0 0z"
        fill="#540F16"
      />
    </svg>
  );
}

export default SvgTwitter;
