import * as React from "react";

function SvgFlame(props) {
  return (
    <svg
      viewBox="0 0 68 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.028 86c-48.48-31.145 11.943-58.84 3.239-84 0 0 26.755 9.13 15.688 36.014-9.438 22.928 22.773 23.841 19.635 3.247 0 0 22.57 34.087-17.105 44.739M24.267 2c21.356 17.45-20.749 60.971 9.716 84"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFlame;
