import React, { useState }from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Modal } from 'components';
import { HCPConfirm } from "../Modals";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { colors, breakpoints } from "styles";

const BelowFoldBanner = () => {
  const [hcpConfirmOpen, setHcpConfirmOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      thumbnail: file(relativePath: { eq: "download-thumbnail.png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 100
            height: 200
          )
        }
      }
    }
  `);
  return (
    <Container>
      <InnerContainer>
        <GatsbyImage
          image={getImage(data.thumbnail)}
          alt="Key Facts and clinical data for all Healthcare Professionals (HCP's)"
        />
        <div>
          <h5>
            Key Facts and clinical data for
            <br />
            all Healthcare Professionals (HCP's)
          </h5>
          <Button
            onClick={() =>
              setHcpConfirmOpen(true)
            }
          >
            Download
          </Button>
        </div>
      </InnerContainer>
      {hcpConfirmOpen && (
        <Modal
          darkBackground={true}
          isModalOpen={hcpConfirmOpen}
          closeModal={() => setHcpConfirmOpen(false)}
        >
          <HCPConfirm closeModal={() => setHcpConfirmOpen(false)} />
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  width: 100%;
  z-index: 1;
  ${breakpoints.lg} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  grid-column: 1 / 13;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #ffffff;
  gap: 2rem;
  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  h5 {
    font-size: 0.925rem;
    font-weight: 400;
    line-height: 1.4em;
    text-align: center;
  }
  ${breakpoints.sm} {
    flex-direction: row;
    gap: 6rem;
    h5 {
      text-align: left;
      font-size: 1rem;
    }
  }
  ${breakpoints.lg} {
    grid-column: 2 / 12;
    h5 {
      font-size: 1.75rem;
      line-height: 2.75rem;
    }
  }
`;

const Button = styled.button`
  align-self: center;
  text-transform: uppercase;
  outline: none;
  border: none;
  border-radius: 1.875rem;
  background: ${colors.orange};
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  cursor: pointer;
  width: 100%;
  max-width: 180px;
  ${breakpoints.sm} {
    align-self: flex-start;
  }
`;

export default BelowFoldBanner;

