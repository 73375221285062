import * as React from "react";

function SvgCheckmarkCircled(props) {
  return (
    <svg
      viewBox="0 0 117 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M108.935 41.569c1.313 4.562 1.97 9.496 1.97 14.431 0 29.793-24.39 54-54.406 54C26.482 110 2 85.886 2 56S26.389 2 56.405 2c12.1 0 23.17 3.91 32.175 10.52"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M114 25.183L101.243 12.52 55.561 57.769 35.112 37.566 22.355 50.228 42.71 70.524l12.851 12.662 12.757-12.662L114 25.183z"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgCheckmarkCircled;
