import * as React from "react";

function SvgHeatCell(props) {
  return (
    <svg
      viewBox="0 0 88 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.836 69.02c0-8.236-4.18-8.236-4.18-16.472s4.18-8.236 4.18-16.471c0-8.236-4.18-8.236-4.18-16.472 0-8.235 4.18-8.235 4.18-16.471m19.084-.067c0 8.236-4.179 8.236-4.179 16.471 0 8.236 4.18 8.236 4.18 16.472 0 8.235-4.18 8.235-4.18 16.47 0 8.237 4.18 8.237 4.18 16.472M55.074 3.067c0 8.236-4.18 8.236-4.18 16.471 0 8.236 4.18 8.236 4.18 16.472 0 8.235-4.18 8.235-4.18 16.47 0 8.237 4.18 8.237 4.18 16.472M74.159 3.067c0 8.236-4.179 8.236-4.179 16.471 0 8.236 4.18 8.236 4.18 16.472 0 8.235-4.18 8.235-4.18 16.47 0 8.237 4.18 8.237 4.18 16.472M2 60.984v11.65C2 76.72 5.413 80 9.662 80h68.676C82.588 80 86 76.72 86 72.635v-11.65M21.92 8.021l-5.154-4.955-5.154 4.955m29.393 0l-5.154-4.955-5.154 4.955m29.393 0L54.935 3l-5.154 4.955m29.393.067L74.02 3.067l-5.154 4.955"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgHeatCell;
