import * as React from "react";

function SvgHeatpadNeck(props) {
  return (
    <svg
      viewBox="0 0 186 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M184 43.765c0 7.398-6.311 13.185-13.723 12.599-10.862-.952-25.246-1.831-31.777-.879-11.522 1.758-33.538 12.452-45.5 12.452-11.962 0-33.978-10.767-45.5-12.525-6.532-.953-20.915-.073-31.777.879C8.311 56.95 2 51.163 2 43.765v-27.76C2 9.413 6.99 3.992 13.522 3.406 24.53 2.454 40.455 1.282 47.5 2.381 59.022 4.139 81.038 14.833 93 14.833c11.962 0 33.978-10.694 45.5-12.452 7.045-1.026 22.97.073 33.978 1.025A12.613 12.613 0 01184 16.005v27.76z"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M32.264 45.45a4.6 4.6 0 01-4.623-4.615V18.35a4.6 4.6 0 014.623-4.615 4.6 4.6 0 014.623 4.615v22.56c0 2.49-2.054 4.54-4.623 4.54zM47.281 45.45a4.6 4.6 0 01-4.623-4.615V18.35a4.6 4.6 0 014.623-4.615 4.6 4.6 0 014.623 4.615v22.56c0 2.49-2.054 4.54-4.623 4.54zM88.524 27.629c-.807 2.417-3.376 3.735-5.797 2.93l-21.43-7.105c-2.421-.806-3.742-3.37-2.935-5.787.807-2.417 3.376-3.736 5.798-2.93l21.429 7.105c2.422.806 3.743 3.37 2.935 5.787zM88.524 42.033c-.807 2.417-3.376 3.736-5.797 2.93l-21.43-7.105c-2.421-.806-3.742-3.37-2.935-5.787.807-2.417 3.376-3.735 5.798-2.93l21.429 7.105c2.422.733 3.743 3.37 2.935 5.787zM88.524 56.364c-.807 2.417-3.376 3.736-5.797 2.93l-21.43-7.105c-2.421-.806-3.742-3.37-2.935-5.786.807-2.418 3.376-3.736 5.798-2.93l21.429 7.105c2.422.805 3.743 3.369 2.935 5.786zM96.598 27.629c.807 2.417 3.376 3.735 5.798 2.93l21.429-7.105c2.421-.806 3.742-3.37 2.935-5.787-.807-2.417-3.376-3.736-5.798-2.93l-21.429 7.105c-2.421.806-3.669 3.37-2.935 5.787zM96.598 42.033c.807 2.417 3.376 3.736 5.798 2.93l21.429-7.105c2.421-.806 3.742-3.37 2.935-5.787-.807-2.417-3.376-3.735-5.798-2.93l-21.429 7.105c-2.421.733-3.669 3.37-2.935 5.787zM96.598 56.364c.807 2.417 3.376 3.736 5.798 2.93l21.429-7.105c2.421-.806 3.742-3.37 2.935-5.786-.807-2.418-3.376-3.736-5.798-2.93l-21.429 7.105c-2.421.805-3.669 3.369-2.935 5.786zM139.234 45.45a4.6 4.6 0 01-4.624-4.615V18.35a4.6 4.6 0 014.624-4.615 4.6 4.6 0 014.623 4.615v22.56c0 2.49-2.055 4.54-4.623 4.54zM154.25 45.45a4.6 4.6 0 01-4.623-4.615V18.35a4.6 4.6 0 014.623-4.615 4.6 4.6 0 014.624 4.615v22.56c0 2.49-2.055 4.54-4.624 4.54z"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHeatpadNeck;
