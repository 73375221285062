import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { colors, breakpoints, H5, P } from "styles";
import { useWindowSize } from "hooks";

const BenefitsButton = ({
  top,
  right,
  left,
  bottom,
  width,
  title,
  text,
  svg,
}) => {
  const [open, setOpen] = useState(false);
  const { windowWidth } = useWindowSize();
  const mobile = windowWidth < 768;
  return (
    <Container
      $top={top}
      $left={left}
      $right={right}
      $bottom={bottom}
      $open={open}
      onMouseEnter={() => !mobile && setOpen(true)}
      onMouseLeave={() => !mobile && setOpen(false)}
    >
      <Dot
        $open={open || mobile}
        $right={right}
        initial={{ transform: "scale(1) translate(-50%, -50%)" }}
        animate={
          open
            ? { transform: "scale(2) translate(-50%, -50%)" }
            : { transform: "scale(1) translate(-50%, -50%)" }
        }
        transition={{ duration: 0.375 }}
      >
        <>
          {svg}
          <span />
          <span />
        </>
      </Dot>
      <AnimatePresence>
        {(open || windowWidth < 768) && (
          <Content
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            width={width}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <H5>{title}</H5>
            <P>{text}</P>
          </Content>
        )}
      </AnimatePresence>
    </Container>
  );
};

const pulse = keyframes`
    0% {
      box-shadow: 0 0 0 0 rgba(255, 173, 142, 0.9);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;

const Dot = styled(motion.div)`
  align-items: center;
  animation: ${pulse} 1s infinite;
  background-position: center;
  background: white;
  border-radius: 50%;
  border: 0.375rem solid ${colors.red};
  box-shadow: 0 0 0 0 rgba(255, 173, 142, 0.1);
  cursor: pointer;
  display: flex;
  height: auto;
  justify-content: center;
  left: 50%;
  min-height: 1.5rem;
  min-width: 1.5rem;
  overflow: hidden;
  padding: 1.5rem;
  position: absolute;
  top: 25%;
  width: auto;
  z-index: 3;
  svg {
    display: block;
    height: 3.5rem;
    width: 3.5rem;
  }
  span {
    background: white;
    background: white;
    display: ${({ $open }) => ($open ? "none" : "block")};
    height: 0.125rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.875rem;
    &:last-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  ${breakpoints.sm} {
    background: ${({ $open }) => ($open ? "white" : colors.red)};
    border: 0.2rem solid ${colors.red};
    left: ${({ $right }) => ($right ? "100%" : "50%")};
    padding: 0.5rem;
    top: 50%;
    z-index: ${({ $open }) => ($open ? "3" : "1")};
    svg {
      display: ${({ $open }) => ($open ? "block" : "none")};
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  ${breakpoints.md} {
    min-height: 2.5rem;
    min-width: 2.5rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  ${breakpoints.lg} {
    top: 100%;
    z-index: ${({ $open }) => ($open ? "3" : "1")};
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

const Content = styled(motion.div)`
  background: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(0, 90, 133, 0.2);
  color: ${colors.brown};
  height: auto;
  margin-top: 7rem;
  max-width: 416px;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 2;
  ${H5} {
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
  }
  ${P} {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    text-align: center;
  }
  ${breakpoints.sm} {
    left: ${({ left }) => (left ? "0rem" : "initial")};
    margin: 0;
    max-width: 80vw;
    padding-bottom: 0.5rem;
    padding-left: ${({ left }) => (left ? "3rem" : "1.5rem")};
    padding-right: ${({ right }) => (right ? "1rem" : "1.5rem")};
    padding-top: 1.5rem;
    position: absolute;
    right: ${({ right }) => (right ? "0rem" : "initial")};
    top: 0;
    width: ${({ width }) => (width ? width : "600px")};
    ${H5} {
      text-align: left;
    }
    ${P} {
      text-align: left;
    }
  }
  ${breakpoints.md} {
    padding-left: ${({ left }) => (left ? "4rem" : "1.5rem")};
    padding-right: ${({ right }) => (right ? "4rem" : "1.5rem")};
    ${P} {
      font-size: 1rem;
    }
  }
  ${breakpoints.lg} {
    padding-left: ${({ left }) => (left ? "4rem" : "1.5rem")};
    padding-right: ${({ right }) => (right ? "4rem" : "1.5rem")};
    ${P} {
      font-size: 1rem;
    }
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 3;
  ${breakpoints.sm} {
    bottom: ${({ $bottom }) => $bottom};
    left: ${({ $left }) => $left};
    min-height: 1.5rem;
    min-width: 1.5rem;
    padding: 0.5rem;
    position: absolute;
    right: ${({ $right }) => $right};
    top: ${({ $top }) => $top};
    z-index: unset;
  }
  ${breakpoints.lg} {
    min-height: 2.5rem;
    min-width: 2.5rem;
  }
`;

export default BenefitsButton;
