exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-everyday-pain-js": () => import("./../../../src/pages/everyday-pain.js" /* webpackChunkName: "component---src-pages-everyday-pain-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-heat-therapy-js": () => import("./../../../src/pages/heat-therapy.js" /* webpackChunkName: "component---src-pages-heat-therapy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-thermacare-lower-back-and-hip-index-js": () => import("./../../../src/pages/products/thermacare-lower-back-and-hip/index.js" /* webpackChunkName: "component---src-pages-products-thermacare-lower-back-and-hip-index-js" */),
  "component---src-pages-products-thermacare-neck-and-shoulders-index-js": () => import("./../../../src/pages/products/thermacare-neck-and-shoulders/index.js" /* webpackChunkName: "component---src-pages-products-thermacare-neck-and-shoulders-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-warnings-js": () => import("./../../../src/pages/warnings.js" /* webpackChunkName: "component---src-pages-warnings-js" */)
}

