import React from "react";
import styled from "styled-components";
import { H4, P } from "../../styles";

const Card = ({ svg, title, text }) => {
  return (
    <StyledCard>
      {svg}
      <H4 $center>{title}</H4>
      <P $center>{text}</P>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 20rem;
  margin: 0 auto;
  svg {
    width: 10rem;
    height: 7rem;
    margin-bottom: 2rem;
  }
`;

export default Card;
