import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { breakpoints, colors, Container, H3, LinkButton } from "styles";
import { Arrow, CEMark } from "assets/svgs";
import { StaticImage } from "gatsby-plugin-image";

const ProductsBanner = () => {
  const [index, setIndex] = useState(0);
  const slides = useMemo(
    () => [
      {
        id: 1,
        title: "ThermaCare® Lower back & hips",
        link: "/products/thermacare-lower-back-and-hip/",
        image: (
          <StaticImage
            src="../../assets/images/neck-pain-relief.png"
            alt="Neck pain"
            placeholder="blurred"
            layout="fullWidth"
            loading="lazy"
            objectFit="contain"
            objectPosition="center"
          />
        ),
      },
      {
        id: 2,
        title: "ThermaCare® Neck, shoulder and wrist",
        link: "/products/thermacare-neck-and-shoulders/",
        image: (
          <StaticImage
            src="../../assets/images/back-pain-relief.png"
            alt="Back pain"
            placeholder="blurred"
            layout="fullWidth"
            loading="lazy"
            objectFit="contain"
            objectPosition="center"
          />
        ),
      },
    ],
    []
  );

  const paginate = useCallback(
    (dir) => {
      dir === "next" && index !== slides.length - 1
        ? setIndex(index + 1)
        : dir === "next" && index === slides.length - 1
        ? setIndex(0)
        : dir === "prev" && index === 0
        ? setIndex(slides.length - 1)
        : dir === "prev" && index !== 0
        ? setIndex(index - 1)
        : setIndex(1);
    },
    [index, slides]
  );

  useEffect(() => {
    const timer = setTimeout(() => paginate("next"), 5000);
    return () => clearTimeout(timer);
  }, [paginate]);

  return (
    <Container>
      <SlidesContainer $colors={colors}>
        <AnimatePresence exitBeforeEnter>
          <Slide>
            <AnimatedImage
              key={`${slides[index].id}-image`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.375 }}
            >
              <CEMark />
              {slides[index].image}
            </AnimatedImage>
            <Content>
              <AnimatedText
                key={`${slides[index].id}-text`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.375 }}
              >
                <H3>{slides[index].title}</H3>
                <LinkButton to={slides[index].link}>View Product</LinkButton>
              </AnimatedText>
            </Content>
          </Slide>
        </AnimatePresence>

        <Prev onClick={() => paginate("prev")}>
          <Arrow />
        </Prev>
        <Next onClick={() => paginate("next")}>
          <Arrow />
        </Next>
      </SlidesContainer>
    </Container>
  );
};

const AnimatedText = styled(motion.div)``;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: 100%;
  justify-content: center;
  padding: 3rem 1.5rem;
  width: 100%;
  a {
    max-width: 220px;
    margin: 2rem auto;
  }
  ${breakpoints.md} {
    align-items: flex-start;
    grid-column: 3/7;
    padding: 3rem;
    a {
      margin: 2rem 0;
    }
  }
  ${breakpoints.lg} {
    grid-column: 5/12;
    padding: 4rem;
  }
`;

const AnimatedImage = styled.div`
  grid-column: 1/7;
  height: 100%;
  max-height: 200px;
  padding-top: 2rem;
  position: relative;
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  svg {
    position: absolute;
    bottom: 0.75rem;
    right: 20%;
    width: 1.75rem;
  }
  ${breakpoints.sm} {
    svg {
      right: 30%;
    }
  }
  ${breakpoints.md} {
    grid-column: 1/3;
    padding: 0;
    svg {
      width: 2.375rem;
    }
  }
  ${breakpoints.lg} {
    grid-column: 1/5;
    svg {
      bottom: 0.75rem;
      right: 22%;
    }
  }
`;

const Paginate = styled.button`
  align-items: center;
  background: none;
  border-radius: 50%;
  border: 2px solid ${colors.red};
  bottom: 1.5rem;
  bottom: 2rem;
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  outline: none;
  position: absolute;
  width: 1.5rem;
  ${breakpoints.sm} {
    width: 2rem;
    height: 2rem;
  }
  svg {
    height: 0.75rem;
    width: 0.75rem;
  }
  ${breakpoints.sm} {
    top: 50%;
    bottom: initial;
  }
`;

const Prev = styled(Paginate)`
  left: 1rem;
  transform: translateY(-50%) rotate(-90deg);

  svg {
    margin-top: 0.125rem;
  }
  ${breakpoints.md} {
    left: 2.5rem;
    transform: translateY(-50%) rotate(-90deg);
  }
`;
const Next = styled(Paginate)`
  right: 1rem;
  transform: translateY(-50%) rotate(90deg);
  svg {
    margin-top: 0.125rem;
  }
  ${breakpoints.md} {
    right: 2.5rem;
    transform: translateY(-50%) rotate(90deg);
  }
`;

const Slide = styled.article`
  align-items: center;
  background: ${colors.lightGrey};
  display: grid;
  height: auto;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  ${breakpoints.lg} {
    grid-template-columns: repeat(10, 1fr);
  }
`;

const SlidesContainer = styled.div`
  grid-column: 1/7;
  justify-content: center;
  position: relative;
  ${breakpoints.sm} {
    padding: 4rem;
  }
  ${breakpoints.md} {
    padding: 4rem 8rem;
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
  }
`;

export default ProductsBanner;
