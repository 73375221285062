import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { breakpoints, colors, H2, H4, P } from "styles";
import { Arrow } from "assets/svgs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Carousel = ({ slides }) => {
  const [index, setIndex] = useState(0);

  const paginate = useCallback(
    (dir) => {
      dir === "next" && index !== slides.length - 1
        ? setIndex(index + 1)
        : dir === "next" && index === slides.length - 1
        ? setIndex(0)
        : dir === "prev" && index === 0
        ? setIndex(slides.length - 1)
        : dir === "prev" && index !== 0
        ? setIndex(index - 1)
        : setIndex(1);
    },
    [index, slides]
  );

  useEffect(() => {
    const timer = setTimeout(() => paginate("next"), 5000);
    return () => clearTimeout(timer);
  }, [paginate]);

  return (
    <SlidesContainer $colors={colors}>
      <AnimatePresence exitBeforeEnter>
        <Slide layout>
          <AnimatedImage
            key={`${slides[index].id}-image`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.375 }}
          >
            <GatsbyImage
              image={getImage(slides[index].image)}
              alt={slides[index].title}
            />
            {}
          </AnimatedImage>
          <Content>
            <AnimatedText
              key={`${slides[index].id}-text`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.375 }}
            >
              {slides[index].step && <H4>Step {slides[index].step}</H4>}
              <H2 $color={colors.brown} dangerouslySetInnerHTML={{ __html: slides[index].title }} />
              <P dangerouslySetInnerHTML={{ __html: slides[index].text }} />
            </AnimatedText>
            <Indicators $size={slides.length}>
              {slides.map((slide) => (
                <Indicator
                  whileTap={{ scale: 0.9 }}
                  key={slide.id}
                  $active={slides[index].id === slide.id}
                  onClick={() => setIndex(slide.id - 1)}
                >
                  {slide.id}
                </Indicator>
              ))}
            </Indicators>
          </Content>
        </Slide>
      </AnimatePresence>

      <Prev onClick={() => paginate("prev")}>
        <Arrow />
      </Prev>
      <Next onClick={() => paginate("next")}>
        <Arrow />
      </Next>
    </SlidesContainer>
  );
};

const AnimatedText = styled(motion.div)``;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: 100%;
  justify-content: space-between;
  padding: 3rem 1.5rem;
  width: 100%;
  ${breakpoints.md} {
    grid-column: 3/7;
    padding: 2rem;
  }
  ${breakpoints.lg} {
    grid-column: 6/12;
    padding: 3rem 4rem;
    min-height: 380px;
  }
`;

const AnimatedImage = styled.div`
  grid-column: 1/7;
  height: 100%;
  max-height: 250px;
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  ${breakpoints.md} {
    max-height: 100%;
    grid-column: 1/3;
  }
  ${breakpoints.lg} {
    grid-column: 1/6;
  }
`;

const Paginate = styled.button`
  align-items: center;
  background: none;
  border-radius: 50%;
  border: 2px solid ${colors.red};
  bottom: 1.5rem;
  bottom: 2rem;
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  outline: none;
  position: absolute;
  width: 1.5rem;
  ${breakpoints.sm} {
    width: 2rem;
    height: 2rem;
  }
  svg {
    height: 0.75rem;
    width: 0.75rem;
  }
  ${breakpoints.sm} {
    top: 50%;
    bottom: initial;
  }
`;

const Prev = styled(Paginate)`
  left: 1rem;
  transform: translateY(-50%) rotate(-90deg);

  svg {
    margin-top: 0.125rem;
  }
  ${breakpoints.md} {
    left: 2.5rem;
    transform: translateY(-50%) rotate(-90deg);
  }
`;
const Next = styled(Paginate)`
  right: 1rem;
  transform: translateY(-50%) rotate(90deg);
  svg {
    margin-top: 0.125rem;
  }
  ${breakpoints.md} {
    right: 2.5rem;
    transform: translateY(-50%) rotate(90deg);
  }
`;

const Indicator = styled(motion.button)`
  align-items: center;
  align-items: center;
  background: ${({ $active }) => ($active ? colors.red : "transparent")};
  border: 2px solid ${colors.red};
  color: ${({ $active }) => ($active ? "white" : colors.red)};
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  font-size: 1rem;
  font-weight: 600;
  height: 1.5rem;
  justify-content: center;
  line-height: 1em;
  margin-right: 0.5rem;
  outline: none;
  transition: 0.1s;
  width: 1.5rem;
  ${breakpoints.sm} {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
`;

const Indicators = styled.div`
  align-items: center;
  display: flex;
  height: 1rem;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  ${breakpoints.md} {
    justify-content: flex-start;
  }
`;

const Slide = styled(motion.article)`
  align-items: center;
  background: ${colors.lightGrey};
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  ${breakpoints.lg} {
    grid-template-columns: repeat(10, 1fr);
  }
`;

const SlidesContainer = styled.div`
  grid-column: 1/7;
  justify-content: center;
  position: relative;
  ${breakpoints.sm} {
    padding: 4rem;
  }
  ${breakpoints.md} {
    padding: 4rem 8rem;
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
  }
`;

export default Carousel;
