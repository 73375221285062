import React from "react";
import styled from "styled-components";
import { colors, breakpoints, Section, Container, H6 } from "styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import {
  Facebook,
  LinkedIn,
  Twitter,
  AngeliniPharma,
  ISCPBackground,
} from "assets/svgs";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
        }
      }
      carysfort: file(relativePath: { eq: "carysfort.png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            quality: 90
            width: 110
            height: 37
          )
        }
      }
      iscp: file(relativePath: { eq: "iscp-blue-background-footer.png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            quality: 90
            width: 220
            height: 110
          )
        }
      }
    }
  `);
  return (
    <StyledFooter>
      <GatsbyImage
        image={getImage(data.bottomFlame)}
        alt="Background"
        className="fire"
      />
      <Container style={{ gridGap: "2rem", padding: "40px 40px 20px 40px" }}>
        <Sitemap>
          <H6 $color="white">Sitemap</H6>
          <Link to="/">Home</Link>
          <Link to="/products/">Products</Link>
          <Link to="/heat-therapy/">Heat Therapy</Link>
          <Link to="/everyday-pain/">Everyday Pain</Link>
          <Link to="/faq/">FAQ</Link>
          <Link to="/warnings/">Warnings</Link>
          <Socials>
            <Link to="/" title="Facebook">
              <Facebook />
            </Link>
            <Link to="/" title="LinkedIn">
              <LinkedIn />
            </Link>
            <Link to="/" title="Twitter">
              <Twitter />
            </Link>
          </Socials>
        </Sitemap>
        <Products>
          <H6 $color="white">Products</H6>
          <Link to="/products/thermacare-lower-back-and-hip/">
            ThermaCare® Lower back & hip
          </Link>
          <Link to="/products/thermacare-neck-and-shoulders/">
            ThermaCare® Neck, shoulder, wrist
          </Link>
          <Supported>
            <ISCPBackground />
            <p style={{ marginTop: '1.5rem' }}>
              Company registered in UK No: 12666897
              <br />
              VAT No: GB 372 7244 88
              <br />
              GB EORI no: GB372 7244 88000
            </p>
          </Supported>
        </Products>
        <Contacts>
          <H6 $color="white">Contacts</H6>
          <p>
            <strong>Carysfort Healthcare UK Ltd</strong>
            <br />
            Livingstone House
            <br />
            309 Harrow Road
            <br />
            Wembley
            <br />
            Middlesex HA9 6BD
            <br />
            UK
            <br />
          </p>
          <p style={{ marginBottom: "0px" }}>
            Tel: <strong>0044 755 561 145 5</strong>
          </p>
          <p>
            Email: <strong>quality@carysforthealthcareuk.co.uk</strong>
          </p>
          <p>
            Sales and marketing UK:
            <br />
            <strong>info@dendronbrandsltd.co.uk </strong>
            <br />
            <br />
            Sales and marketing Northern Ireland:
            <br />
            <strong>all@johnsonbrothers.co.uk</strong>
          </p>
        </Contacts>
      </Container>
      <Section $bg="rgba(0,0,0,.25)" style={{ zIndex: "2" }}>
        <Copyright>
          <div>
            Exclusively distributed in
            <br /> Ireland, Northern Ireland
            <br /> and UK by
            <GatsbyImage
              image={getImage(data.carysfort)}
              alt="Carysfort"
              objectFit="contain"
            />
          </div>
          <div>
            Copyright © 2022 Angelini Pharma S.p.A <AngeliniPharma />
          </div>
          <div>
            {/* <Link to="/terms/">Terms & Conditions</Link> */}
            <Link to="/privacy-policy/">Privacy Policy</Link>
            <Link to="/terms-and-conditions/">Terms & Conditions</Link>
          </div>
        </Copyright>
      </Section>
    </StyledFooter>
  );
};

const Copyright = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 100%;
  padding: 1.5rem 1rem;
  position: relative;
  width: 1440px;
  z-index: 2;
  div {
    align-items: center;
    color: white;
    display: flex;
    font-size: 0.875rem;
    gap: 0.75rem;
    justify-content: center;
    a {
      color: white;
      margin-right: 1rem;
      text-decoration: underline;
      text-align: center;
    }
    .gatsby-image-wrapper {
      position: relative;
    }
  }
  div:first-of-type {
    justify-content: space-between;
  }
  ${breakpoints.sm} {
    div:first-of-type {
      justify-content: center;
      gap: 2rem;
      br {
        display: none;
      }
    }
  }
  ${breakpoints.md} {
    grid-template-columns: 1fr 1fr 1fr;
    div:first-of-type {
      gap: 1rem;
      br {
        display: inline-block;
      }
    }
  }
  ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr 1fr;
    div:first-of-type {
      align-items: flex-start;
      justify-content: flex-end;
    }
    div:last-of-type {
      justify-content: flex-start;
    }
  }
  ${breakpoints.xl} {
    grid-template-columns: 1fr 1.25fr 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${H6} {
    text-transform: uppercase;
  }
  a {
    align-items: flex-start;
    color: white;
    display: flex;
    font-family: "Gotham";
    justify-content: flex-start;
    line-height: 1.5em;
    margin-bottom: 0.5em;
    text-decoration: none;
    svg {
      height: 1rem;
      margin-right: 1rem;
      margin-top: 0.25em;
      width: 1rem;
    }
  }
`;

const Sitemap = styled(Column)`
  ${breakpoints.sm} {
    grid-column: 1/3;
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
  }
`;

const Products = styled(Column)`
  ${breakpoints.sm} {
    grid-column: 3/5;
  }
  ${breakpoints.lg} {
    grid-column: 5/8;
  }
`;

const Contacts = styled(Column)`
  p {
    color: white;
    font-family: "Gotham";
    line-height: 1.5em;
    margin-bottom: 0.5em;
    &:nth-of-type(2) {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-top: 1rem;
    }
  }
  ${breakpoints.sm} {
    grid-column: 5/7;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

const Socials = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-top: 1rem;
  a {
    align-items: center !important;
    background: white;
    border-radius: 50%;
    display: flex;
    height: 2rem !important;
    justify-content: center !important;
    margin-right: 1rem !important;
    width: 2rem !important;
    svg {
      margin: 0;
      path {
        fill: ${colors.brown};
      }
    }
  }
`;

const Supported = styled.div`
  align-items: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "Gotham";
  gap: 1rem;
  justify-content: flex-start;
  line-height: 1.5em;
  margin-top: 1rem;
  padding: 0;
  position: relative;
  .gatsby-image-wrapper {
    position: relative !important;
  }
  svg {
    width: 100%;
    max-width: 220px;
  }
  ${breakpoints.md} {
    margin-top: 3rem;
  }
`;

const StyledFooter = styled.footer`
  background: ${colors.red};
  position: relative;
  width: 100%;
  font-size: 0.875rem;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
  }
`;

export default Footer;
