import React from "react";
import { Layout } from "components";
import { AnimatePresence } from "framer-motion";

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location

const wrapPageElement = ({ element, props }) => (
      <AnimatePresence exitBeforeEnter>
        <Layout {...props}>{element}</Layout>
      </AnimatePresence>
);

export default wrapPageElement;
