import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Checkmark } from "assets/svgs";
import { colors, breakpoints } from "styles";

const HCPConfirm = ({ closeModal }) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (checked) {
      setTimeout(() => {
        window.open(
          "/thermacare-key-facts-and-clinical-data-for-all-healthcare-professionals-hcps.pdf",
          "_blank"
        );
        closeModal();
      }, [1000]);
    }
  }, [checked]);
  return (
    <ModalBody
      key="modal"
      role="dialog"
      aria-label="HCP Confirmation"
      checked={checked}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.25 }}
    >
      <h2>I confirm I am a Healthcare Professional</h2>
      <button
        onClick={() => {
          setChecked(true);
        }}
      >
        <Checkmark />
      </button>
    </ModalBody>
  );
};

const ModalBody = styled(motion.div)`
  align-items: center;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: ${colors.brown};
  display: flex;
  height: auto;
  justify-content: center;
  padding: 2.875rem;
  position: relative;
  text-align: center;
  width: auto;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: overlay;
  overscroll-behavior: none;
  h5 {
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin: 0;
    margin-block-end: 0;
    position: relative;
    z-index: 3;
    color: ${colors.brown};
  }
  button {
    height: 1.25rem;
    width: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 3px;
    border: 1px solid ${colors.brown};
    svg {
      opacity: ${({ checked }) => (checked ? 1 : 0)};
      height: 75%;
      width: 75%;
      transition: opacity 0.3s ease-in-out;
    }
  }
  ${breakpoints.sm} {
    padding: 3rem;
    gap: 2rem;
  }
  ${breakpoints.md} {
    h5 {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    button {
      height: 1.5rem;
      width: 1.5rem;
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }
`;

export default HCPConfirm;
