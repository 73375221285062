import * as React from "react";

function SvgBrain(props) {
  return (
    <svg
      viewBox="0 0 88 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.99 32.368h11.82l3.001-9.456 5 23.64 6.729-31.277 5.273 24.73 3.183-7.728H67.27M23.628 10.365c1.727-3.546 8.546-13.366 21.366-4.456M57.723 7.092C62.636 5.182 76 10.365 76 24.276M78.908 53.462c-1.545 5.455-7.546 8.728-11.638 8.91M52.632 57.099c.727 4.364 3.364 8.183 10.456 9.183s10.001 10.183 10.001 10.183M70.998 61.463c6.273 3.364 6.91 13.91 6.91 13.91M5.625 29.277c-6.635 5.455-6.18 28.185 18.821 24.367M11.899 38.369c2.455 2.273 4.546 3.818 9.092 1.09M17.627 41.005c-.091 4 2.818 6.092 6 6.82"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M43.903 9.092c0-3.91 3.183-7.092 7.092-7.092 3.91 0 7.092 3.182 7.092 7.092M74.09 24.73c3.636-1.363 7.728.455 9.182 4.092 1.455 3.637-.454 7.728-4.091 9.183M64.997 41.005c2 2.455 1.546 6.001-.818 7.91-2.455 2-6.001 1.546-7.91-.818M27.628 41.824c-10.001 4.637-1.182 28.822 19.185 15.184 6.182.727 10.638.727 14.456.545M25.264 8.092c-7.18-2.91-14.454.727-13.91 9.637M17.627 20.093c-5.273-7.182-19.457 1.819-10.729 10.82"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M83.09 34.368a11.853 11.853 0 012.91 7.82c0 6.637-5.364 11.91-11.91 11.91-4.092 0-7.638-2-9.82-5.182"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgBrain;
