import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { colors, Button } from "styles";

const OfficialSite = ({ closeModal }) => {
  const [showVideo, setShowVideo] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      videoThumbnail: file(relativePath: { eq: "advert-video-thumbnail.png" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  `);
  return (
    <ModalBody
      key="modal"
      role="dialog"
      aria-label="Product How To Use"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.25 }}
    >
      <AnimatePresence>
        {showVideo ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, delay: 1 }}
            style={{
              padding: "56.25% 0 0 0",
              height: "100%",
              width: "100%",
              position: "relative",
              overflow: "hidden",
              marginBottom: "2rem",
            }}
          >
            <iframe
              title="Thermacare® TV Advert"
              src="https://player.vimeo.com/video/917484358?autoplay=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              referrerPolicy="strict-origin"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <h2>
              This is the Official Thermacare® website
              <br />
              for UK including Northern Ireland
            </h2>
            <p>
              Thermacare® Heat Wraps are <strong>Clinically Proven</strong>,{" "}
              <strong>100% Drug Free</strong> and{" "}
              <strong>Provide Constant Temperature throughout use</strong>.
              Always read instructions carefully.
            </p>
            <p>Check out our new Thermacare® TV Advert.</p>
            <ImageContainer
              whileHover={{ scale: 1.01 }}
              whileTap={{
                scale: 0.95,
              }}
              onClick={() => setShowVideo(true)}
            >
              <GatsbyImage
                image={getImage(data.videoThumbnail)}
                alt="Thermacare® TV Advert"
              />
            </ImageContainer>
          </motion.div>
        )}
      </AnimatePresence>
      <Button onClick={() => closeModal()}>OK</Button>
    </ModalBody>
  );
};

const ModalBody = styled(motion.div)`
  align-items: center;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: ${colors.brown};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  max-width: 750px;
  max-height: 100%;
  padding: 3rem 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: overlay;
  overscroll-behavior: none;
  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 1.375rem;
  }
  button {
    margin: 0 auto;
    max-height: 40px;
    max-width: 100px;
  }
  div {
    height: 100%;
  }
`;

const ImageContainer = styled(motion.button)`
  background: none;
  border: none;
  cursor: pointer;
  min-width: 212px;
  width: 100%;
  min-height: 120px;
  margin-bottom: 1.375rem !important;
`;

export default OfficialSite;
