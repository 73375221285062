import * as React from "react";

function SvgCheckmark(props) {
  return (
    <svg
      viewBox="0 0 33 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33 4.48L28.406 0 11.957 16.008 4.594 8.86 0 13.34l7.33 7.18L11.957 25l4.594-4.48L33 4.48z"
        fill="#CC112C"
      />
    </svg>
  );
}

export default SvgCheckmark;
