import * as React from "react";

function SvgArrow(props) {
  return (
    <svg
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.667 7.324L7.01 1.667 1.353 7.324"
        stroke="#CC112C"
        strokeWidth={props.stroke || 2}
      />
    </svg>
  );
}

export default SvgArrow;
