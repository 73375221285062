import * as React from "react";

function SvgStress(props) {
  return (
    <svg
      viewBox="0 0 108 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M69.817 114V95.333h20.6c8.566 0 4.997-23.665 4.997-23.665h6.935c2.651 0 4.487-2.856 3.263-5.202L95.414 45.454h-.102C94.088 23.624 77.975 5.774 57.07 2l-8.362 22.033h17.235L25.965 71.26l13.257-29.989H25.965l6.629-26.725"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M34.736 3.326C15.768 9.344 2 26.991 2 47.902c0 16.32 8.363 30.703 21.11 39.067V114"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgStress;
