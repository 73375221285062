import * as React from "react";

function SvgDrugFree(props) {
  return (
    <svg
      viewBox="0 0 114 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.454 99.44C8.29 95.573 2.304 83.457 6.16 72.257l18.774-55.692c3.754-11.2 15.83-17.207 26.992-13.44 11.163 3.767 17.15 15.883 13.395 27.083L46.548 86c-3.856 11.2-15.932 17.207-27.094 13.44zM55.884 58.104L15.496 44.461M90.284 72.256c11.993 0 21.716-9.755 21.716-21.788 0-12.034-9.723-21.789-21.716-21.789s-21.716 9.755-21.716 21.789c0 12.033 9.723 21.788 21.716 21.788zM103.375 67.878L77.092 33.16M77.498 100.764c6.109 0 11.061-4.968 11.061-11.098 0-6.129-4.952-11.097-11.06-11.097-6.11 0-11.062 4.968-11.062 11.097 0 6.13 4.952 11.098 11.061 11.098zM88.254 87.528L66.64 91.703M2 12.287L103.273 114"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgDrugFree;
