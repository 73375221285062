import * as React from "react";

function SvgWeighingScale(props) {
  return (
    <svg
      viewBox="0 0 97 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72.875 26.63h12.07c5.689 0 10.287 4.822 10.057 10.573l-2.644 67.038c-.23 5.46-4.655 9.759-10.058 9.759H15.117c-5.345 0-9.77-4.241-10.057-9.701L2.013 37.261c-.288-5.809 4.31-10.63 10.057-10.63h12.07"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M48.507 51.261c13.458 0 24.367-11.027 24.367-24.63C72.874 13.027 61.965 2 48.507 2 35.049 2 24.139 13.027 24.139 26.63c0 13.604 10.91 24.631 24.368 24.631zM94.312 51.261H77.358c-6.955 8.307-17.3 13.594-28.909 13.594-11.609 0-21.954-5.287-28.908-13.594H2.645M37.645 10.714L47.07 24.48"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M48.508 29.245c1.428 0 2.586-1.17 2.586-2.614s-1.158-2.614-2.586-2.614-2.586 1.17-2.586 2.614c0 1.443 1.158 2.614 2.586 2.614zM48.506 45.859v5.809M48.506 2v5.81M29.716 26.805h-5.747M73.046 26.805h-5.747M35.233 40.282l-4.08 4.067M65.865 9.261l-4.023 4.125M35.233 13.386l-4.08-4.125M65.865 44.349l-4.023-4.067"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgWeighingScale;
