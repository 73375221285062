import * as React from "react";

function SvgPosture(props) {
  return (
    <svg
      viewBox="0 0 88 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.312 27.491c6.143 0 11.123-4.947 11.123-11.05 0-6.104-4.98-11.051-11.123-11.051-6.143 0-11.123 4.947-11.123 11.05 0 6.104 4.98 11.051 11.123 11.051zM2 22.339s6.141 41.763.205 62.44M66.416 40.034V2c3.207 0 5.8 2.576 5.8 5.763V34.27c.068 3.187-2.525 5.763-5.8 5.763zM2.205 90.814V84.78h38.281v6.034c0 3.186-2.593 5.762-5.8 5.762H8.006c-3.208 0-5.8-2.576-5.8-5.762zM21.313 117.051V96.576M7.186 117.051H35.23M86 118V61.118H34.685"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M72.283 22.339h1.706c2.866 0 5.186 2.305 5.186 5.152V61.12M23.291 72.712h28.046c3.07 0 5.731 2.237 6.209 5.288l4.776 30.915c.683 4.271-2.66 8.068-6.96 8.068-3.48 0-6.482-2.508-6.96-5.966L44.65 86.746c-.205-1.288-1.296-2.237-2.661-2.237H10.19V43.627c0-6.305 5.117-11.39 11.463-11.39 6.551 0 11.805 5.492 11.464 12l-.546 6.577"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M17.83 44.102L23.7 61.119h24.906c2.866 0 5.186-2.305 5.186-5.153 0-2.847-2.32-5.152-5.186-5.152h-18.97M30.591 61.118l-1.364 11.594"
        stroke="#540F16"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPosture;
