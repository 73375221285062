import React, { useState, useEffect } from "react";
import { Logo } from "assets/svgs";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize } from "hooks";
import { Link } from "gatsby";
import { colors, breakpoints } from "styles";

const Navbar = () => {
  const location = useLocation();
  const { windowWidth, windowHeight } = useWindowSize();
  const isMobile = windowWidth < 768;
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  return (
    <>
      <NavBg scrolled={isScrolled} />
      <Nav scrolled={isScrolled} isMobile={isMobile} $colors={colors}>
        <LogoButton to="/" title="Homepage">
          <Logo alt="Logo" />
        </LogoButton>
        <AnimatePresence>
          {(!isMobile || navOpen) && (
            <Links
              $navOpen={navOpen}
              $colors={colors}
              $windowHeight={windowHeight}
              initial={
                isMobile ? { opacity: 1, x: "100%" } : { opacity: 0, y: -50 }
              }
              animate={isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }}
              exit={
                isMobile ? { opacity: 1, x: "100%" } : { opacity: 0, y: -50 }
              }
              transition={{ duration: 0.5 }}
            >
              <div>
                <CustomLink
                  $active={activeLink === "/products/"}
                  to="/products/"
                  onClick={() => {
                    toggleActiveLink("/products/");
                  }}
                >
                  Products
                </CustomLink>
                <CustomLink
                  $active={activeLink === "/heat-therapy/"}
                  to="/heat-therapy/"
                  onClick={() => {
                    toggleActiveLink("/heat-therapy/");
                  }}
                >
                  Heat Therapy
                </CustomLink>
                <CustomLink
                  $active={activeLink === "/everyday-pain/"}
                  to="/everyday-pain/"
                  onClick={() => {
                    toggleActiveLink("/everyday-pain/");
                  }}
                >
                  Everyday Pain
                </CustomLink>
                <CustomLink
                  $active={activeLink === "/faq/"}
                  to="/faq/"
                  onClick={() => {
                    toggleActiveLink("/faq/");
                  }}
                >
                  FAQ
                </CustomLink>
              </div>
            </Links>
          )}
        </AnimatePresence>
        {isMobile && (
          <MenuToggle $navOpen={navOpen} onClick={toggleNav}>
            <span />
          </MenuToggle>
        )}
      </Nav>
    </>
  );
};

const MenuToggle = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  grid-area: toggle;
  grid-column: 6/7;
  height: 0.75rem;
  justify-content: center;
  margin: 0.5rem 1.5rem;
  width: 1.5rem;
  span {
    background: ${colors.darkRed};
    content: "";
    display: block;
    height: 2px;
    margin-top: -0.75rem;
    position: relative;
    transform: ${({ $navOpen }) =>
      $navOpen ? "translateY(6px) rotate(45deg)" : "none"};
    transition: 0.375s;
    width: 1.5rem;
    :before,
    :after {
      background: ${colors.darkRed};
      content: "";
      display: block;
      height: 2px;
      opacity: 1;
      position: absolute;
      transform-origin: center;
      transition: 0.375s;
      width: 1.5rem;
    }
    :before {
      opacity: ${({ $navOpen }) => ($navOpen ? 0 : 1)};
      top: 6px;
    }
    :after {
      top: 12px;
      transform: ${({ $navOpen }) =>
        $navOpen ? "translateY(-12px) rotate(-90deg)" : "none"};
    }
  }
  ${breakpoints.sm} {
    display: none;
  }
`;

const LogoButton = styled(Link)`
  align-items: center;
  display: flex;
  grid-area: logo;
  grid-column: 1/5;
  height: 5rem;
  justify-content: flex-start;
  max-width: 14rem;
  padding: 0.5rem 1rem;
  width: 14rem;
  svg {
    height: auto;
    max-width: 100%;
    width: 100%;
    path {
      fill: ${colors.darkRed};
    }
  }
  ${breakpoints.sm} {
    grid-column: 1/4;
    padding: 1rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/4;
    padding: 0;
    padding: 1rem;
  }
`;

const CustomLink = styled(Link)`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  border-bottom: 1px solid white;
  color: ${colors.brown};
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  height: auto;
  justify-content: center;
  line-height: 1em;
  padding: 1.5rem;
  text-align: center;
  text-decoration: none;
  width: 90%;
  div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: auto;
  }

  ${breakpoints.sm} {
    border-bottom: ${({ $active }) => $active ? `2px solid ${colors.darkRed}` : "2px solid transparent"};
    border-top: 2px solid transparent;
    color: ${colors.darkRed};
    font-size: 1rem;
    height: 2rem;
    justify-content: center;
    margin: 0 1rem;
    padding: 0;
    white-space: nowrap;
    width: auto;
  }
  ${breakpoints.lg} {
    border-bottom: ${({ $active }) =>$active ? `2px solid ${colors.darkRed}` : "2px solid transparent"};
    border-top: 2px solid transparent;
    color: ${colors.darkRed};
    font-size: 1rem;
    height: 2rem;
    justify-content: center;
    margin: 0 1rem;
    padding: 0;
    white-space: nowrap;
    width: auto;
  }
`;

const Links = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(2px);
  box-shadow: ${({ $colors }) => `-2px 4px 4px ${$colors.brown}`};
  display: ${({ $navOpen }) => ($navOpen ? "flex" : "none")};
  flex-direction: column;
  grid-area: links;
  grid-column: 1/7;
  height: ${({ $windowHeight }) => `calc(${$windowHeight}px - 5rem)`};
  justify-content: flex-start;
  margin-top: -1px;
  opacity: 0;
  > div {
    &:first-child {
      align-items: center;
      background: ${({ $colors }) => $colors.lightGrey};
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      width: 100%;
    }
  }
  ${breakpoints.sm} {
    align-items: center;
    backdrop-filter: none;
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    grid-column: 5/13;
    height: auto;
    padding: 0;
    > div {
      &:first-child {
        background: transparent;
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
  ${breakpoints.lg} {
    align-items: center;
    backdrop-filter: none;
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    grid-column: 5/12;
    height: auto;
    padding: 0;
    > div {
      &:first-child {
        background: transparent;
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
`;

const NavBg = styled.div`
  display: none;
  ${breakpoints.sm} {
    background: rgba(229, 229, 229, 0.85);
    display: block;
    height: 5rem;
    left: 0;
    position: fixed;
    top: 0;
    transition: 0.375s;
    width: 100%;
    z-index: 4;
  }
  ${breakpoints.lg} {
    background: rgba(229, 229, 229, 0.85);
    display: block;
    height: 5rem;
    left: 0;
    position: fixed;
    top: 0;
    transition: 0.375s;
    width: 100%;
    z-index: 4;
  }
`;

const Nav = styled.nav`
  align-items: center;
  background: ${({ $colors }) => $colors.lightGrey};
  display: grid;
  grid-column-gap: 1rem;
  grid-template-areas: ${({ $navOpen }) =>$navOpen === true ? '"logo toggle" "links links"' : '"logo toggle"'};
  grid-template-columns: repeat(6, 1fr);
  height: 5rem;
  justify-content: space-between;
  left: 0;
  max-width: 100%;
  position: fixed;
  top: 0;
  transition: 0.375s;
  width: 100vw;
  z-index: 5;
  ${breakpoints.sm} {
    background: transparent;
    grid-gap: 1.25rem;
    grid-template-areas: "logo links";
    grid-template-columns: repeat(12, 1fr);
    padding: 0 1.5rem;
  }
  ${breakpoints.lg} {
    background: transparent;
    box-shadow: none;
    grid-gap: 1.25rem;
    grid-template-areas: "logo links";
    grid-template-columns: repeat(12, 1fr);
    left: 50%;
    max-width: 1440px;
    padding: 0;
    transform: translateX(-50%);
  }
`;

export default Navbar;
